<!--  -->
<template>
  <div class="Activity">
    <div class="head_NavBar">
      <van-icon name="arrow-left" size="20" @click="back()" />
      <div class="title">活动预告</div>
    </div>
    <van-sticky>
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item
          v-model="listDate.type"
          :options="option1"
          @change="getList()"
        />
        <van-dropdown-item :title="date == '' ? '日期' : date" ref="item">
          <van-calendar
            color="#1989fa"
            :poppable="false"
            :show-confirm="false"
            :min-date="minDate"
            :max-date="maxDate"
            type="selected"
            :style="{ height: '500px' }"
            @confirm="onConfirm"
          />
        </van-dropdown-item>
        <van-dropdown-item
          v-model="listDate.timeArea"
          :options="option3"
          @change="getList()"
        />
      </van-dropdown-menu>
    </van-sticky>
    <div class="yugao_cont">
      <ul>
        <li v-for="(item, index) in yugaoList" :key="index">
          <div class="yugao_box">
            <div class="time">
              <span></span>{{ item.activityTime +" "+ item.activityTimeArea }}
            </div>
            <div class="cont_kuang">
              <div class="cont_text">
                <div class="biaoti">
                  {{ item.tittle }}
                </div>
                <div class="dizhi" @click="openMap(item)">
                  <span class="icon iconfont">&#xe61a;</span>{{ item.address }}
                </div>
              </div>
            </div>
            <div class="address">{{ item.activityArea }}</div>
          </div>
        </li>
      </ul>
      <van-empty description="本日没有活动" v-if="isempty" />
    </div>
    <navBer :navNum="3"></navBer>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import wx from "weixin-js-sdk";
import {
  Icon,
  Sticky,
  DropdownMenu,
  DropdownItem,
  Calendar,
  Cell,
  CellGroup,
  Empty,
  Dialog,
} from "vant";
const url='https://tfbookfair2022.symansbon.cn';
export default {
  name: "Activity",
  //import引入的组件需要注入到对象中才能使用
  components: {
    [Icon.name]: Icon,
    [Sticky.name]: Sticky,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Calendar.name]: Calendar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Empty.name]: Empty,
    [Dialog.name]: Dialog,
  },
  data() {
    //这里存放数据
    return {
      option1: [
        { text: "全部", value: null },
        { text: "主会展", value: 23 },
        { text: "分会展", value: 24 },
        { text: "分会场", value: 25 },
      ],
      option3: [
        { text: "全时段", value: "" },
        { text: "全天", value: "全天" },
        { text: "上午", value: "上午 " },
        { text: "下午", value: "下午" },
        { text: "晚上", value: "晚上" },
      ],
      date: "",
      show: false,
      dataShow: false,
      minDate: new Date(2022, 10, 1),
      maxDate: new Date(2022, 10, 30),
      yugaoList: [],
      isempty: false,
      listDate: {
        dateTime: "",
        type: null,
        timeArea: "",
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    back() {
      this.$router.back();
    },
    formatDate(date) {
      //console.log(date);

      return `${date.getMonth() + 1}月${date.getDate()}日`;
    },
    onConfirm(date, type) {
      this.date = this.formatDate(date);
      //转换日期
      this.listDate.dateTime =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      // //console.log( '传值',this.listDate.dateTime);
      this.getList();

      this.$refs.item.toggle();
    },
    getList() {
      let that = this;
      this.getRequest(
        `${url}/api/Activity`,
        that.listDate
      ).then((res) => {
        //console.log(res);
        this.yugaoList = res;
        if (this.yugaoList.length == 0) {
          this.isempty = true;
        } else {
          this.isempty = false;
        }
      });
    },
    openMap(item) {
      //console.log(item);
      Dialog.confirm({
        title: "提示",
        message: "是否打开微信导航？",
        beforeClose: function (action, done) {
          if (action === "confirm") {
            wx.openLocation({
              latitude: Number(item.latitude), // 纬度，浮点数，范围为90 ~ -90
              longitude: Number(item.longitude), // 经度，浮点数，范围为180 ~ -180。
              name: item.address, // 位置名
              address: item.activityArea, // 地址详情说明
              scale: 12, // 地图缩放级别,整型值,范围从1~28。默认为最大
              infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
            });
            done();
          } else {
            done();
          }
        },
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getRequest(
      `${url}/api/WechatConfig`,
      {}
    ).then((res) => {
      //console.log("微信参数", res);
      wx.config({
        // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        debug: false,
        // 必填，公众号的唯一标识
        appId: res.appId,
        // 必填，生成签名的时间戳
        timestamp: res.timestamp,
        // 必填，生成签名的随机串
        nonceStr: res.nonceStr,
        // 必填，签名，见附录1
        signature: res.signature,
        // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        jsApiList: ["checkJsApi", "openLocation"],
        success(res) {
          //console.log("config返回", res);
        },
      });
    });
    this.getList();

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style >
</style>
<style scoped lang="less">
.Activity {
  overflow: hidden;
  padding-bottom: 80px;
}
.yugao_cont {
  position: relative;
  padding: 30px 27px;
  li {
    margin-bottom: 19px;
    &:nth-child(2n) {
      .yugao_box .cont_kuang .cont_text {
        background: #eef9ff;
      }
    }
  }
  .yugao_box {
    position: relative;
    padding-left: 40px;
    .time {
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-bottom: 15px;
      span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #d8d8d8;
        display: inline-block;
        margin-right: 18px;
      }
    }
    .cont_kuang {
      border-left: 1px solid #d8d8d8;
      padding-left: 20px;
      margin-left: 5px;
      .cont_text {
        background: #fff8f2;
        border-radius: 6px 6px 6px 6px;
        padding: 20px 10px;
        .biaoti {
          font-size: 13px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          line-height: 1.4;
          margin-bottom: 17px;
        }
        .dizhi {
          font-size: 12px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          span {
            font-size: 12px;
            color: #ed6c00;
            margin-right: 8px;
          }
        }
      }
    }
    .address {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      font-size: 12px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #ed6c00;
    line-height: 1.2;      
    }
  }
}
</style>